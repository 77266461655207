<template>
  <div class="recommend-dialog" v-show="visible">
    <div class="shade" @click="hide"></div>
    <div :style="(hasLoadBg && hasLoadData)?'':'top:-100%'" class="main">
      <div class="bg">
        <van-image @load="hasLoadBg=true" src="https://cdn.001ppt.cn/h5/static/image/recommend-dialog-bg.png"></van-image>
      </div>
      <div class="close-btn" @click="hide">
        <van-icon name="cross" />
      </div>
      <div class="title">邀请好友首次绑定手机号</div>
      <div class="award-yanzhi">+{{config.recommendAwardYanzhi}}严值(￥{{config.recommendAwardYanzhi/10}})</div>
      <div class="award-vip">每邀请5位好友+送试用会员(￥49)</div>
      <div class="btn" @click="invite"></div>
      <div class="statistic">已成功邀请 {{$store.state.share.inviteData.inviteNum }} 位好友，共奖励严值{{$store.state.share.inviteData.inviteYanzhi }}(￥{{$store.state.share.inviteData.inviteYanzhi/10 }})</div>
      <div class="record">
        <img class="avatar" v-for="(item,index) in $store.state.share.inviteData.inviteUsers"
             :src="item.avatar || 'https://cdn.001ppt.cn/h5/static/lazy/avatar.png'"
             @click="gotoUserDetail(item)">
      </div>
      <div class="tip">
        <div class="tip-item">*新用户通过本链接首次绑定手机号</div>
        <div class="tip-item">双方都会获得奖励严值{{config.recommendAwardYanzhi}}(¥{{config.recommendAwardYanzhi/10}})噢~</div>
        <div class="tip-item">邀请人数无上限~</div>
      </div>
      <div class="rpxBorder"></div>
      <div class="go-vip-btn">赶时间？</div>
      <div class="footer">
        <div class="footer-btn" @click="$router.push('/topUpMoney')">直接充值</div>
        <div class="footer-btn" @click="$router.push('/member')">加入会员</div>
      </div>
    </div>
    <div v-if="!hasLoadBg || !hasLoadData" class="main" style="background: #f2f2f2;border-radius: 6px">
      <div class="title" style="">
        <div class="skeleton-block" style="width: 80%;height: 30px"></div>
      </div>
      <div class="award-yanzhi">
        <div class="skeleton-block" style="width: 150px;height: 36px;margin-top: 8px"></div>
      </div>
      <div class="award-vip">
        <div class="skeleton-block" style="width: 200px;height: 24px;margin-top: 8px"></div>
      </div>
      <div class="skeleton-block" style="width: 80%;height: 240px;margin: 24px auto">
      </div>
    </div>
  </div>
</template>

<script>
import {taskV2Api} from "../../api/v2/taskV2Api";

export default {
  data(){
    return{
      visible: false,
      hasLoadBg: false,
      hasLoadData: false,
      config: {
        recommendAwardYanzhi: 0
      }
    }
  },


  methods:{

    async toggleDialog() {
      this.visible = !this.visible
      this.loadRecommendAwardYanzhi().then()
      await this.$store.dispatch('share/loadInviteData')
      this.hasLoadData = true
    },

    hide(){
      this.visible = false
    },


    invite(){
      this.hide();
      this.$emit('share')
    },


    /**
     * 加载首次奖励严值
     */
    async loadRecommendAwardYanzhi() {
      const res = await taskV2Api.findById(6);
      this.config.recommendAwardYanzhi = res.value;
    },


    gotoUserDetail(item){
      this.$router.push('/otherPerson/'+item.id)
    }

  }

}

</script>

<style scoped lang="less">
@keyframes zoomIn {
   from{
     transform-origin: 0 0;
     opacity: 0;
     transform: translateX(-50%) translateY(-51%);
   }
  to{
    transform-origin: 0 0;
    opacity: 1;
    transform:  translateX(-50%) translateY(-50%);
  }
}



.recommend-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}

.recommend-dialog .shade {
  background: #151D36;
  opacity: .95;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}


.recommend-dialog .main {
  //background-image: url("https://cdn.001ppt.cn/h5/static/image/recommend-dialog-bg.png");
  background-size: cover;
  z-index: 2;
  width: 320px;
  height: 423px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: zoomIn .2s ease-in-out;
}

.recommend-dialog .bg{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
}

.recommend-dialog .main .close-btn{
  position: absolute;
  top: -24px;
  right: 0;
  font-size: 20px;
  color: #fff;
}

.recommend-dialog .main .title {
  color: rgba(21, 29, 54, 1);
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  height: 30px;
}

.recommend-dialog .main .award-yanzhi {
  height: 36px;
  color: rgba(225, 73, 78, 1);
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recommend-dialog .main .award-vip {
  height: 18px;
  color: rgba(21, 29, 54, 1);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 11px;
}

.recommend-dialog .main .btn {
  width: 194px;
  height: 43px;
  margin: 16px auto 0 auto;
  background-size: cover;
  background-image: url("https://cdn.001ppt.cn/h5/static/image/recommend-dialog-btn.png");
}

.recommend-dialog .main .statistic {
  height: 14px;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
}

.recommend-dialog .main .record{
  display: flex;
  flex-wrap: nowrap;
  margin: 10px 18px 0 18px;
  max-width: 284px;
  overflow-x: auto;
}

.recommend-dialog .main .record .avatar{
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: rgba(255, 255, 255, 0.2) solid 1px;
  margin-right: 7px;
}

.recommend-dialog .main .tip{
  left: 46px;
  top: 388px;
  opacity: 0.3;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  text-align: center;
  padding: 10px 18px 0 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.recommend-dialog .main .rpxBorder{
  width: 270px;
  height: 1px;
  opacity: 0.3;
  background-color: rgba(248, 208, 125, 1);
  margin: 25px auto 0 auto;
}


.recommend-dialog .main .go-vip-btn{
  height: 18px;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
}


.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
}

.footer-btn{
  width: 80px;
  height: 25px;
  color: rgba(80, 80, 80, 1);
  background-color: #fff;
  border-radius: 13px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  border: 1px solid rgba(21, 29, 54, 1);
}

</style>
