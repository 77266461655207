<template>
  <van-popup v-model="visible" position="bottom" round closeable>
    <div class="common-share-sheet">
      <div class="title">立即分享给好友</div>
      <div class="desc">
        <div>新用户通过你的分享🔗首次绑定手机号</div>
        <div>双方都会获得💰{{yanzhiFilter(recommendAwardYanzhi)}}奖励严值噢
          <template>
            (¥{{moneyFilter(recommendAwardYanzhi / 10)}})
          </template>
          ~邀请人数无上限✌️~
        </div>
      </div>

      <div class="options">
        <div class="option-item" @click="$emit('share','sharePost')">
          <img class="option-icon" src="https://img.yzcdn.cn/vant/share-icon-poster.png" alt="">
          <div class="option-text">💰分享海报</div>
        </div>
        <div class="option-item" @click="onCopyUrl">
          <img class="option-icon" src="https://img01.yzcdn.cn/vant/share-sheet-link.png" alt="">
          <div class="option-text">💰复制链接</div>
        </div>
      </div>

    </div>
  </van-popup>
</template>

<script>
import {taskV2Api} from "../api/v2/taskV2Api";
import {copyToClipBoard} from "../config/util";

let recommendAwardYanzhiProm = null

export default {
  props:['innerCopy'],

  data() {
    return {
      visible: false,
      recommendAwardYanzhi: 0
    }
  },


  methods: {

    async show(){
      await this.loadRecommendAwardYanzhi()
      this.visible = true
    },


    hide(){
      this.visible = false
    },

    async loadRecommendAwardYanzhi() {
      if (recommendAwardYanzhiProm == null) {
        recommendAwardYanzhiProm = ((async () => {
            const res = await taskV2Api.findById(6)
            return res.value
          }
        )())
      }
      this.recommendAwardYanzhi = await recommendAwardYanzhiProm
    },


    onCopyUrl(){
      if(this.innerCopy){
        copyToClipBoard(window.location.href)
        this.$toast.success('复制成功，快去分享吧~')
        this.hide()
      }  else{
        this.$emit('share','copyUrl')
      }
    }

  }
}
</script>

<style scoped lang="less">
.common-share-sheet {
  padding: 24px;
}

.common-share-sheet .title {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.common-share-sheet .desc {
  font-size: 12px;
  color: #969799;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
}

.common-share-sheet .options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.common-share-sheet .option-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 0 !important;
}

.common-share-sheet button {
  width: 50px !important;
  height: 50px !important;
  padding: 0 !important;
  background: transparent !important;
  margin: 0 !important;
}

.common-share-sheet .option-icon {
  height: 50px;
  width: 50px;
  display: inline-block;
}

.common-share-sheet .option-text {
  font-size: 12px;
  padding-top: 10px;
}


</style>
